import React, { useState } from "react";

const Testimonials = ({ classicHeader, darkTheme }) => {
  const [expandedTestimonials, setExpandedTestimonials] = useState({});

  const truncateText = (text, wordLimit = 50) => {
    const words = text.split(" ");
    if (words.length <= wordLimit) return text;
    const truncated = words.slice(0, wordLimit).join(" ");
    // Find the last paragraph break before the truncation point
    const lastParagraph = truncated.lastIndexOf("\n\n");
    if (lastParagraph > truncated.length * 0.7) {
      // If we're more than 70% through, use that paragraph break
      return truncated.slice(0, lastParagraph) + "...";
    }
    return truncated + "...";
  };

  // Function to format text with paragraphs
  const formatText = (text) => {
    return text.split("\n\n").map((paragraph, i) => (
      <p key={i} className="mb-3">
        {paragraph.trim()}
      </p>
    ));
  };

  const toggleTestimonial = (index) => {
    setExpandedTestimonials((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const reviews = [
    {
      name: "Brooke MacLean",
      position: "CEO @ Marketwake/Punchlist",
      src: "images/testimonial/brooke.jpg",
      desc: `Working with Nic as our fractional CTO has been a game-changer for Marketwake and Punchlist. He is a true leader, team player, and problem solver—always bringing clarity and structure to even the most complex technical challenges. His ability to assess a request, quickly identify dependencies, and map out a clear path to success has been invaluable in optimizing our infrastructure and refining our product roadmap. Nic works efficiently while keeping a sharp focus on aligning our technical strategy with market needs and business goals.

Recently, we pivoted our product to serve a new, more niche audience—a shift that brought added complexities, new user stories, and the need to reimagine core features. Nic has been a rock throughout this process, helping us concept, architect, and develop a new version of the platform while ensuring our existing users remain supported. Balancing innovation with stability is no easy task, but Nic's strategic vision and steady leadership have made it possible.

Beyond his technical expertise, Nic has a rare and invaluable trait—patience. He is an exceptional teacher, ensuring that our entire team not only understands the 'what' but also the 'why' behind technical decisions. This has created a ripple effect, fostering stronger collaboration, better alignment, and genuine buy-in across the organization. His ability to cast a compelling vision while equipping the team with the knowledge to execute has led to measurable improvements in system performance, scalability, and overall efficiency.`,
    },
    {
      name: "Shelli Pavone",
      position: "CEO @ Inlightened",
      src: "images/testimonial/shelli.jpg",
      desc: `From the start, you helped us navigate a major rewrite and stabilization of our codebase, ensuring we have a strong foundation to build on for the future. Your ability to explain deeply technical concepts in a straightforward and digestible way has made a significant impact, allowing our team to stay aligned and make informed decisions. You are incredibly collaborative, responsive, and solution-oriented—without your expertise, we wouldn't have been able to accomplish such an aggressive advancement of our platform.
      It's been a pleasure working with you, and I look forward to continuing our collaboration!`,
    },
    {
      name: "Emily Shapiro",
      position: "CEO @ TALD",
      src: "images/testimonial/emily.jpg",
      desc: "Nic has been a tactical thought partner who keeps the big picture in mind — never overbuilding, always prioritizing efficiency. He has been instrumental in qualifying and onboarding new talent to build a sustainable development organization. He translates initiatives to a non-technical co-founder effectively, ensuring alignment and clarity. Most importantly, he allows me to focus on growing the business with confidence, knowing that the tech is in good hands.",
    },
    {
      name: "Matt Melchiori",
      position: "COO @ Glass",
      src: "images/testimonial/matt.jpg",
      desc: "Nic is a true pro. Whether we were talking about 0-1 solutions, building for scale, or growing our team, Nic had a thoughtful, results-oriented approach that allowed us to move quickly and deliver with confidence. Nic evaluated and built recommendations for moving off legacy code, supplementing our team with the right talent, and improving our processes. I couldn't have been happier with Nic's partnership and technical leadership.",
      // rating: 5,
    },
    {
      name: "Lucas Longacre",
      position: "Head of Product @ Inlightened",
      src: "images/testimonial/lucas.png",
      desc: "Working with Nic and his team has been a game-changer. Rather than just executing requests as written, they actively engage in the process—challenging ideas, identifying opportunities for improvement, and collaborating to build something truly exceptional. This level of partnership has been incredibly rewarding, leading to smarter decisions and a stronger product. The results speak for themselves: since working with Nic, we've increased the number of experts on our panel by 400%, driven largely by the efficiencies and enhancements built into the new platform.",
    },
    {
      name: "William King",
      position: "CEO @ SuperCopy",
      src: "images/testimonial/william.jpg",
      desc: "Nic consistently exceeds the expectations typical of an advisor. He masterfully strikes a delicate balance, offering insightful guidance while nurturing our team's development of unique perspectives. His pivotal role in accelerating our startup's growth, especially as we geared up for our first major enterprise contract, cannot be overstated. Nic's approach is a rare blend of creative technical prowess and deep empathy, allowing him to craft solutions that are as compassionate as they are effective. Whether in 1-on-1 sessions or leading comprehensive team workshops, Nic's focus on unlocking new opportunities is unparalleled. We are incredibly fortunate to have Nic in our corner, and with his support, we're confidently paving our path forward.",
    },
    {
      name: "Tim Min",
      position: "CTO @ Supercopy",
      src: "images/testimonial/tim.jpg",
      desc: "Nic was truly revolutionary for our company. His constant guidance in both our technical direction and scalability of the company were crucial to helping us to where we are today. And ultimately, his care for the founders and their wellbeing is like none other.",
      // rating: 5,
    },
  ];

  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Testimonials
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Words from my clients
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <div className="row g-4">
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="col-12 col-md-6" key={index}>
                <div
                  className={
                    "rounded p-4 h-100 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-start mt-auto mb-3">
                    <img
                      className="img-fluid rounded-circle border d-inline-block"
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                      }}
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {value.position}
                      </span>
                    </p>
                  </div>
                  <div
                    className="testimonial-content"
                    style={{ minHeight: "150px" }}
                  >
                    <div
                      className={
                        "fw-500 mb-2 " +
                        (darkTheme ? "text-white" : "text-dark")
                      }
                    >
                      {expandedTestimonials[index]
                        ? formatText(value.desc)
                        : truncateText(value.desc)}
                    </div>
                    {value.desc.split(" ").length > 50 && (
                      <button
                        className="btn btn-link p-0 text-primary"
                        onClick={() => toggleTestimonial(index)}
                      >
                        {expandedTestimonials[index]
                          ? "Read Less"
                          : "Read More"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
