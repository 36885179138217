import React, { useState } from "react";
import Markdown from "react-markdown";

const CaseStudies = ({ classicHeader, darkTheme }) => {
  const [expandedStudy, setExpandedStudy] = useState(null);

  // Case study data - you could also import these from separate files
  const caseStudies = [
    {
      id: 1,
      title: "Revitalizing a Gridlocked Interior Design Platform",
      summary:
        "How I transformed a technically gridlocked platform with chaotic infrastructure into a reliable system with predictable development cycles, leading to significant business growth and successful seed funding.",
      content: `# Case Study: Revitalizing a Gridlocked Interior Design Platform

## The Challenge

An exclusive interior design platform connecting clients with high-end designers was facing critical technical challenges that threatened its growth. The application had been built haphazardly by a series of freelancers, resulting in:

- A chaotic infrastructure spread across multiple providers
- Inconsistent deployment methods
- A fragmented codebase mixing create-react-app and Next.js
- Numerous security vulnerabilities
- Development gridlock with no releases for months
- Broken staging and QA environments making testing nearly impossible
- An imminent transition from the existing development team with risk of knowledge loss

The founder had lost confidence in the current team and needed a complete technical overhaul to move forward.

## The Approach

As Fractional CTO, I implemented a strategic plan to stabilize the platform and create a foundation for growth:

1. **Knowledge Transfer Management**
   - Conducted intensive knowledge-gathering sessions with the outgoing lead developer
   - Documented all existing processes, infrastructure access, and deployment procedures
   - Created comprehensive documentation where gaps existed

2. **Infrastructure Consolidation**
   - Migrated all services to Vercel for a unified infrastructure approach
   - Provided all stakeholders with appropriate access levels to branch deployments and staging environments
   - Implemented proper environment separation for development, testing, and production

3. **Team Restructuring**
   - Assembled a new LATAM-based development team with two developers and one QA resource distributed across Argentina, Uruguay, and Bolivia
   - Implemented lightweight Agile practices appropriate for a small team ("agile with a lowercase 'a'")
   - Established rigorous code review processes, multi-stage QA, and detailed release notes

4. **Technical Improvements**
   - Normalized dependency versions across all applications
   - Optimized asset serving, particularly for high-resolution images critical to the platform
   - Implemented coding best practices to improve site performance
   - Enhanced security measures to address vulnerabilities

5. **Process Implementation**
   - Created metrics tracking for QA cycles per feature and development-to-production timelines
   - Established a culture of accountability and collaboration
   - Implemented "bottleneck swarming" to prevent individual team members from getting stuck

## The Results

Over the course of approximately one year, the transformation delivered significant measurable improvements:

### Technical Achievements
- **Deployment Frequency**: From almost no releases to a stable twice-weekly release cycle
- **Path to Continuous Delivery**: Progressed from chaotic manual deployments to evaluating a CD strategy with comprehensive testing in the CI pipeline
- **Performance**: Dramatically improved site speed through asset optimization and coding best practices
- **SEO**: Achieved significant SEO gains through technical improvements
- **Stability**: Reduced load times and delivered more stable user sessions

### Business Impact
- **Predictable Development**: Established confidence in feature and fix delivery timelines
- **Team Alignment**: Created a cohesive development process with clear communication
- **User Base Growth**: Considerably expanded the paying user base
- **User Satisfaction**: Improved application user satisfaction while reducing bugs
- **Funding Success**: Contributed to the company's ability to close its first seed funding round

### Key Milestones
1. Successful knowledge transition and technical ownership
2. Strategic team assembly with the right skill sets
3. Redesign launch with improved performance
4. Establishment of reliable release cycles
5. Implementation of new verticals and subscription growth

## Conclusion

This transformation demonstrates how the right technical leadership can turn around a struggling application through a combination of strategic infrastructure decisions, team building, and process implementation. By focusing on both technical excellence and business outcomes, the platform was able to overcome development gridlock and establish a foundation for sustainable growth and investor confidence.`,
    },
    {
      id: 2,
      title: "Reimagining a Healthcare Professional Network Platform",
      summary:
        "How I transformed an outdated healthcare professional network platform with accumulated technical debt into a reliable system with near-zero bugs and 100% uptime, enabling unprecedented business growth.",
      content: `# Case Study: Revitalizing a Healthcare Professional Network Platform

## The Challenge

A SaaS platform connecting life sciences companies with healthcare professionals (doctors, nurses, and other medical experts) was struggling with significant technical challenges that were hindering growth. Despite being acquired a year earlier, the company was facing:

- A technically outdated platform with years of accumulated technical debt
- Neglected dependency updates that weren't prioritized by the previous outsourced development team
- Frequent regressions when launching new features
- Communication barriers between product management and the development team
- Opaque outsourced development structure with inconsistent team members
- Limited resources dedicated to maintenance, yet insufficient focus on growth initiatives
- Infrastructure and deployment processes that caused interruptions to users

The company found itself in a paradoxical situation: needing to build for growth but unable to move forward due to the constant effort required just to keep the existing platform operational.

## The Approach

As Fractional CTO, I implemented a strategic plan focused on stability first, followed by sustainable growth:

1. **Immediate Stabilization**
   - Paused all new feature development to focus on critical system needs
   - Identified and addressed the most urgent issues impacting reliability
   - Initiated comprehensive dependency updates across the technology stack

2. **Team Restructuring**
   - Replaced the opaque outsourced team with direct leadership
   - Initially handled development personally to stabilize the platform
   - Strategically added a senior frontend engineer and later a mid-level backend engineer

3. **Infrastructure Modernization**
   - Migrated from self-hosted GitLab and Circle CI to GitHub and GitHub Actions
   - Deployed the backend to a new, better-hardened EC2 instance
   - Moved the frontend application to Vercel for improved reliability
   - Implemented multiple environment tiers to enable parallel development and user testing

4. **Technical Debt Resolution**
   - Updated both Laravel (backend) and Angular (frontend) to current versions
   - Made necessary code adaptations to support modern dependencies
   - Established consistent patterns for backend development

5. **Complete Application Rewrite**
   - Applied lessons learned from the original application
   - Rebuilt the platform with a focus on user workflows and automation
   - Maintained the same technology choices (Laravel and Angular) to leverage existing expertise

## The Results

The transformation delivered significant measurable improvements across multiple dimensions:

### Technical Achievements
- **Reliability**: Reduced production bugs to nearly zero
- **Uptime**: Achieved nearly 100% platform availability
- **Deployment**: Established predictable, non-disruptive deployment cycles
- **Development Process**: Implemented structured roadmaps with weekly progress demos

### Business Impact
- **Employee Productivity**: Eliminated dozens of employee hours spent on menial tasks monthly
- **Network Growth**: Increased onboarding capacity by several times
- **User Satisfaction**: Received positive feedback about improved daily work experience
- **Strategic Capabilities**: Enabled new business initiatives previously impossible to implement
- **External Expansion**: Successfully opened the platform to more external companies
- **Unprecedented Growth**: Achieved record growth rates after platform stabilization

### Process Improvements
- **Predictable Development**: Established reliable weekly cycles with consistent delivery
- **Transparent Communication**: Implemented company-wide updates on progress and launch dates
- **User-Centric Design**: Integrated empathy into the product development process
- **Automated Onboarding**: Transformed weeks of manual work into routine automated processes

## Key Insights

This transformation highlighted several important lessons:

1. **Technical Debt Is Business Debt**: Neglecting technical maintenance isn't just a developer concern—it directly impacts business growth potential.

2. **Communication Is Critical**: Direct access between product and development teams prevents misunderstandings and ensures alignment.

3. **Stability Before Growth**: Pausing feature development to focus on stability created the foundation needed for accelerated growth later.

4. **User Empathy Drives Success**: Understanding the healthcare professionals' needs led to meaningful improvements that facilitated network growth.

5. **Predictable Processes Build Trust**: Consistent delivery schedules and transparent communication created organizational confidence.

## Conclusion

This case study demonstrates how strategic technical leadership can transform a struggling platform into a growth engine. By prioritizing stability, modernization, and user needs, the revitalized platform not only resolved its technical challenges but enabled unprecedented business growth and new strategic opportunities in the healthcare professional network space.`,
    },
  ];

  return (
    <section
      id="case-studies"
      className={"section " + (darkTheme ? "bg-dark-1" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50 opacity-1" : "text-light opacity-4")
            }
          >
            Success Stories
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Case studies
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <div className="row">
          {caseStudies.map((study) => (
            <div key={study.id} className="col-md-6 mb-4">
              <div
                className={
                  "case-study-card p-4 rounded " +
                  (darkTheme ? "bg-dark-2" : "bg-white") +
                  " shadow"
                }
              >
                <h3 className={darkTheme ? "text-white" : "text-dark"}>
                  {study.title}
                </h3>
                <p className={darkTheme ? "text-white-50" : "text-dark"}>
                  {study.summary}
                </p>

                <button
                  className="btn btn-primary mt-2"
                  onClick={() =>
                    setExpandedStudy(
                      expandedStudy === study.id ? null : study.id
                    )
                  }
                >
                  {expandedStudy === study.id
                    ? "Close Case Study"
                    : "Read Full Case Study"}
                </button>

                {expandedStudy === study.id && (
                  <div className="mt-4 case-study-content">
                    <div className={darkTheme ? "text-white-50" : "text-dark"}>
                      <Markdown>{study.content}</Markdown>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CaseStudies;
