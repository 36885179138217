import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      "name": "From Idea to Innovation",
      "desc": "Together, we'll transform your vision into a market-ready MVP. I'll guide you through the critical decisions of build vs. buy, technology selection, and architectural design that align with your business goals and budget. We'll create a foundation that can scale with your success, avoiding costly rebuilds down the road.",
      "icon": "fas fa-lightbulb"
    },
    {
      "name": "Team Transformation",
      "desc": "Whether your tech team needs a performance boost or you're building from scratch, we'll cultivate the talent and processes needed for success. I'll help identify skill gaps, improve collaboration, and implement effective workflows that deliver results. Watch as productivity increases and your team develops a renewed sense of purpose and direction.",
      "icon": "fas fa-users-cog"
    },
    {
      "name": "Strategic Technology Roadmapping",
      "desc": "We'll craft a product roadmap that balances immediate wins with long-term growth. By prioritizing features that deliver the most value and mapping dependencies, we'll create a clear path forward. Your technology investments will directly support your business objectives, with measurable outcomes every step of the way.",
      "icon": "fas fa-route"
    },
    {
      "name": "Solution Architecture & Technical Guidance",
      "desc": "Complex technical decisions require experienced oversight. Together, we'll design systems that are robust yet flexible, scalable yet manageable. I'll bring clarity to technical trade-offs and help implement solutions that stand the test of time and growth. Your architecture will become a competitive advantage rather than a limitation.",
      "icon": "fas fa-drafting-compass"
    },
    {
      "name": "Investor & Stakeholder Communication",
      "desc": "Translating technical progress into business value is essential for stakeholder confidence. Having been through successful fundraising and exits, I'll help you articulate your technology story in terms investors understand and value. We'll prepare for due diligence, highlight technical innovations, and demonstrate how your technology drives business growth.",
      "icon": "fas fa-handshake"
    },
    {
      "name": "Operational Excellence & Process Optimization",
      "desc": "We'll identify and eliminate bottlenecks that slow your progress. By implementing right-sized processes for your current stage, we'll improve delivery predictability without unnecessary bureaucracy. Your operations will become more efficient, allowing your team to focus on innovation rather than firefighting.",
      "icon": "fas fa-cogs"
    },
    {
      "name": "Advisory Partnership",
      "desc": "Sometimes you just need an experienced sounding board. I'll provide honest, constructive feedback on technical directions, hiring decisions, or strategic pivots. Our regular advisory sessions will help you navigate challenges with confidence, drawing on lessons learned across multiple successful tech companies.",
      "icon": "fas fa-comment-dots"
    }
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            How we can work together
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                        {service.desc.split("\n").map((item, key) => {
                          return (
                            <span key={key}>
                              {item}
                              <span style={{ paddingBottom: 10 + 'px', display: 'block' }}></span>
                            </span>
                          );
                        })}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
